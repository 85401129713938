export const environment = {
  DIRECT_API_BASE_URL: process.env.DIRECT_API_BASE_URL || '',
  STABLECOIN_API_BASE_URL: process.env.STABLECOIN_API_BASE_URL || '',
  AUTHORIZATION_URL: `${process.env.OAUTH_PROVIDER_URL || ''}/oauth/authorize`,
  TOKEN_URL: `${process.env.OAUTH_PROVIDER_URL || ''}/oauth/token`,
  CLIENT_ID: process.env.DIRECT_OAUTH_CLIENT_ID || '',
  CALLBACK_URL: `${process.env.DIRECT_WEB_URL || ''}/oauth/callback`,
  SCOPE: 'profile,issuances.write,issuances.read',
  ID_URL: process.env.OAUTH_PROVIDER_URL || '',
  DIRECT_WEB_URL: process.env.DIRECT_WEB_URL || '',
  DIRECT_SENTRY_DSN: process.env.DIRECT_SENTRY_DSN || '',
  NODE_ENV: process.env.NODE_ENV || '',
  ENVIRONMENT: process.env.BILIRA_ENV || '',
  STABLECOIN_WEB_URL: process.env.STABLECOIN_WEB_URL || '',
  KYC_URL: `${process.env.STABLECOIN_WEB_URL || ''}/account/id-verification`,
  CDN_BASE_URL: process.env.CDN_BASE_URL || '',
  IS_LOCAL: process.env.BILIRA_ENV === 'development',
  IS_STAGING: process.env.BILIRA_ENV === 'staging',
  IS_SANDBOX: process.env.BILIRA_ENV === 'sandbox',
  IS_PROD: process.env.BILIRA_ENV === 'production',
};
